@import "../../styles/variables.scss";
.quick-links {
  margin-top: 10px;
  width: 100%;
  font-family: $eyInterstateRegularNew;
  font-weight: 400;
  font-size: $fontSize18;
  color: $breadcrumb-color-active;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 20px;
  flex: 1;
  .ag-body-vertical-scroll{
    max-width: 5px !important;
    width: 5px !important;
    background-color: $ey-phoenix-light-gray-2 !important;
  }
  .ag-body-vertical-scroll-viewport{
    max-width: 5px !important;
    width: 5x !important;
    background-color: $ey-phoenix-light-gray-2 !important;
  }
  .header-padding{
    margin-bottom: 20px;

  }
  .appAlert.MuiAlert-colorError {
    padding: 0px;
    background-color: inherit !important;
    border: none !important;
    margin: 0px;
  }
  .ag-root-wrapper {
    width: 100% !important;
  }
  .ag-cell-value-invalid > div > span {
    color: $motif-error-red !important;
  }
  .ag-cell-inline-editing {
    box-shadow: none !important;
  }
  .ag-input-field-input {
    height: 100% !important;
    padding-bottom: 0px !important;
  }
  .ag-cell-inline-editing {
    box-shadow: none !important;
  }
  .ag-cell-edit-wrapper {
    padding: 0px 12px;
  }
  .ag-cell-normal-height {
    height: 100%;
  }
  .ag-center-cols-container,
  .ag-floating-bottom-container {
    width: 100% !important;
  }
.list{
  .app-table{
    height: 180px !important;
    
  }
  .ag-root-wrapper{
    max-height: 180px !important;
  }
  .ag-body-viewport{
    min-height: unset !important;
  }
}
  .list-input {
    border: none;
    border-bottom: 2px solid $motif-searchbox-outline; /* Set underline color */
    padding: 5px;
    outline: none;
    width: 100%;
  }
  .list-input-error {
    border: none;
    border-bottom: 2px solid $ey-phoenix-red-1; /* Set underline color */
    padding: 5px;
    outline: none;
    width: 100%;
  }
  .edit-icon {
    width: 20px;
    padding-right: 8px;
  }
  .save-icon {
    width: 23px;
    padding-right: 8px;
  }
  .delete-icon {
    width: 20px;
    padding-left: 8px;
  }
  .quick-links-list {
    margin-top: 30px;
    border-top: 1px solid $light-border;
    width: 100%;
  }
  .add-icon {
    margin-right: 10px;
  }
  .no-link-found {
    margin-top: 10px;
  }
  .add-new-link-button {
    float: right;
    margin-bottom: 10px;
    border: 1px solid $ey-phoenix-light-gray-2;
    border-radius: 4px;
    margin-right: 30px;
  }
  .add-quick-links {
    margin-top: 10px;
    border-top: 2px solid $light-border;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .no-links {
    font-family: $eyInterstateRegularNew;
    font-weight: 400;
    font-size: $fontSize16;
    color: $breadcrumb-color-active;
  }
  .start-addition-links {
    font-family: $eyInterstateLightNew;
    font-weight: 300;
    font-size: $fontSize13;
    color: $ey-phoenix-light-gray;
    margin-bottom: 20px;
  }
  .link-logo-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 21px;
    border-radius: 50%;
    margin-left: 5px;
    border: 1px solid $ey-off-white;
  }
  .link-logo-url {
    width: 17px;
  }
  .link-container {
    display: flex;
    align-items: center;
  }
  .link-container a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 800px; 
  }
}
