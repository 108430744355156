@import "../../styles/globalStyles.scss";
@import "../../styles/variables.scss";

.project-home {
  padding: 12px 0px;
  font-family: $eyInterstateRegularNew !important;
  border-radius: 8px;

  .project-home-divider-line {
    border-bottom: 1px solid $light-border;
    margin: 14px 0;
  }

  .pl-0 {
    padding-left: 0px !important;
  }

  .project-home-info {
    display: flex;
    align-items: center;
    flex-direction: row;
    .draft-icon {
      font-size: $fontSize12;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      color: $ey-link-blue;
      background-color: $ey-light-blue-1;
      padding: 3px 8px 3px 8px;
      border-radius: 16px;
      margin-left: 12px;
      margin-top: 3px;
    }
    .project-home-label, .project-home-value{
      font-size: $fontSize16;
      color: $disabled_motif_background;
      margin-right: 4px;
    }
  }
  .project-home-name {
    margin: 0;
    font-size: $fontSize20;
    color: $secondary_button_grey;
    font-weight: 400;
    line-height: 30px;
  }

  .project-home-info-icon {
    margin-left: 12px;
    margin-top: -5px;
    font-size: $fontSize16;
    width: 16px;
    height: 16px;
    color: $black;
  }

  .project-home-text-right {
    text-align: right;
    margin-left: auto;
  }

  .project-home-label {
    font-size: $fontSize16;
    color: $ey-phoenix-light-gray-3;
    margin-right: 8px;
    font-weight: 400;
  }

  .project-home-value {
    font-size: $fontSize16;
    color: $selected_color;
  }

  .project-home-total-user {
    font-size: $fontSize14;
    margin-left: 8px;
    border: 1px solid $light-border;
    padding: 2px 8px;
    border-radius: 16px;
  }

  .remaining-count {
    font-size: $fontSize14;
    color: $ey-phoenix-light-gray;
    background-color: $light-border;
    border-radius: 200px;
    padding: 2px 7px;
    cursor: pointer;
  }

  .action-btn {
    display: flex;
    justify-content: flex-end;
    .default-action-button {
      display: flex;
      justify-content: flex-end;
      gap: 0;
      > div:first-child button,
      > .app-tooltip-container:first-child button,
      > button:first-child {
        border-radius: 4px 0 0 4px;
      }
      > .app-tooltip-container:last-child button,
      > button:last-child {
        border-radius: 0 4px 4px 0;
      }
      .app-button {
       border-radius: 0px;
        &:not(.Mui-disabled):hover {
          background-color: $light-border;
        }
      }
      .disabled-btn-default {
        .app-button, &.Mui-disabled {
          color: $ey-phoenix-light-gray-4;
          cursor: not-allowed;
          pointer-events: inherit;
        }
      }
    }
    .disabled-btn {
      a {
        cursor: not-allowed;
      }
      .app-button, &.Mui-disabled {
        color: $disabled_text_color;
        background-color: $disabled_motif_background;
        cursor: not-allowed;
        pointer-events: inherit;
        .icon-link {
          color: $motif-primary-button-icon-color;
        }
      }
    }
    .app-tooltip {
      width: auto;
      height: 90%;
    }
    .app-button {
      height: 36px;
      border: 1px solid $ey-phoenix-light-gray-2;
      line-height: 20px;
    }
    .project-home-edit-btn {
      margin-left: 12px;
      .icon-link {
        margin-right: 8px;
        color: $motif-primary-button-icon-color;
      }
    }
    .announcement-btn {
      cursor: pointer;
      margin-left: 12px;
      height: 36px;
      img:hover {
        opacity: 0.8;
      }
    }
  }
  .project-home-center-label{
    text-align: center;
  }
}
